<template>
  <div class="applicationSettingEdit">
    <template v-if="applicationSetting">
      <form>
        <div class="form-group">
          <h4 class="m-0 mt-3">
            {{ $t('applicationSettingsComponent.applicationSetting') }}
          </h4>
          <hr class="m-0 mb-3">
          <label>{{ $t('key') }}</label>
          <input
            v-model="applicationSetting.key"
            v-focus
            readonly
            :class="['form-control', { 'is-invalid': $validator.errors.has('Key') }]"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>{{ $t('value') }}</label>
          <input
            v-model="applicationSetting.value"
            :class="['form-control', { 'is-invalid': $validator.errors.has('Value') }]"
            type="text"
          >
          <span
            v-show="errors.has('Value')"
            class="badge badge-danger"
          >{{ errors.first('Value') }}
          </span>
        </div>
        <button
          class="btn btn-sm btn-primary float-right"
          @click.prevent="updateApplicationSetting()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="check"
          />{{ $t('save') }}
        </button>
        <div class="clearfix" />      
      </form>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "ApplicationSettingEdit",
  mixins: [
    errorMixin
  ],
  props: {
    applicationSetting: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: true
    }
  },
  methods: {
    updateApplicationSetting () {
      let applicationSettingDto = {
        'id': this.applicationSetting.id,
        'key': this.applicationSetting.key,
        'value': this.applicationSetting.value
      };
      this.axios.put('/ApplicationSettings/UpdateApplicationSetting', applicationSettingDto)
        .then(() => {
          this.$emit("reload");
          this.error_clear();
          this.$snotify.success(this.$t('applicationSettingsComponent.updatedSuccessfully'));
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>