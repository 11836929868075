<template>
  <div class="applicationSettingsAdd">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ $t('applicationSettingsComponent.applicationSetting') }}
        </h4>
        <hr class="m-0 mb-3">
        <label>{{ $t('key') }}</label>
        <input
          v-model="applicationSetting.key"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('Key') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('value') }}</label>
        <input
          v-model="applicationSetting.value"
          :class="['form-control', { 'is-invalid': $validator.errors.has('Value') }]"
          type="text"
        >
      </div>
      <button
        class="btn btn-primary float-right"
        :disabled="!applicationSetting.value || !applicationSetting.key"
        @click.prevent="addApplicationSetting()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('add') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "ApplicationSettingsAdd",
  mixins: [
    errorMixin
  ],
  data () {
    return {
      applicationSetting: {}
    }
  },
  methods: {
    async addApplicationSetting () {
      let postObject = {
        'key': this.applicationSetting.key,
        'value': this.applicationSetting.value
      }
      await this.axios.post('/ApplicationSettings/AddApplicationSetting', postObject)
        .then(() => {
          this.$snotify.success(this.$t('applicationSettingsComponent.addedSuccessfully'));
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>

<style scoped>
.applicationSettingsAdd .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>